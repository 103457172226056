import React from 'react';
import livePerformanceIcon from '../assets/images/Live Immersive Performances Icon.png';
import vrArIcon from '../assets/images/VRAR Experience Icon.png';
import interactiveIcon from '../assets/images/Interactive Installation Design Icon.png';
import mocapIcon from '../assets/images/MoCap and Digital FX Icon.png';
import projectionIcon from '../assets/images/Projection Mapping Icon.png';
import narrativeIcon from '../assets/images/Digital Narrative Icon.png';
import './Services.css';

const Services = () => {
  const services = [
    {
      icon: livePerformanceIcon,
      title: 'Live Immersive Performance Production'
    },
    {
      icon: vrArIcon,
      title: 'VR/AR Experience Design'
    },
    {
      icon: interactiveIcon,
      title: 'Interactive Installation Design'
    },
    {
      icon: mocapIcon,
      title: 'Motion Capture & Digital Effects'
    },
    {
      icon: projectionIcon,
      title: 'Projection Mapping'
    },
    {
      icon: narrativeIcon,
      title: 'Digital Narrative & Storytelling'
    }
  ];

  return (
    <section id="services" className="services-section">
      <div className="services-container">
        <h2 className="services-title">Services</h2>
        <p className="services-description">
          Immerse3D provides creative, tech-driven services that fuse human 
          performance with digital innovation. From volumetric video capture and AR/VR 
          development to motion capture and holograms, we craft immersive experiences 
          that captivate audiences and push storytelling boundaries.
        </p>
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-item">
              <div className="service-icon-container">
                <img src={service.icon} alt={service.title} className="service-icon" />
              </div>
              <h3 className="service-title">{service.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services; 