import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Immerse3Dlogo.png';
import './Nav.css';

const Nav = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav>
      <div className="container">
        <div className="flex">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Immerse3D Logo" className="logo" />
            </Link>
          </div>
          <div className="nav-links">
            <button onClick={() => scrollToSection('about')} className="nav-link">About</button>
            <button onClick={() => scrollToSection('services')} className="nav-link">Services</button>
            <button onClick={() => scrollToSection('team')} className="nav-link">Team</button>
            <button onClick={() => scrollToSection('contact')} className="contact-button">Contact</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav; 