import React from 'react';
import mitLogo from '../assets/images/Mit-Logo-Vector.svg-.png';
import harvardLogo from '../assets/images/harvard-white.png';
import './Partners.css';

const Partners = () => {
  return (
    <section className="partners-section">
      <div className="partners-container">
        <div className="partner-logo-container">
          <img src={harvardLogo} alt="Harvard University" className="partner-logo harvard-logo" />
        </div>
        <div className="partner-logo-container">
          <img src={mitLogo} alt="Massachusetts Institute of Technology" className="partner-logo mit-logo" />
        </div>
      </div>
    </section>
  );
};

export default Partners; 