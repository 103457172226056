import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import cjHeadshot from '../assets/images/CJ_Headshot_3.jpg';
import carlyHeadshot from '../assets/images/carly headshot2.jpeg';
import './Team.css';

const Team = () => {
  const team = [
    {
      name: "CJ Connett",
      role: "Co-Founder & Business Strategy",
      bio: "MBA candidate at MIT Sloan with a background in business strategy, acquisitions, and digital media.",
      image: cjHeadshot,
      linkedin: "https://www.linkedin.com/in/cjconnett/"
    },
    {
      name: "Carly Lave",
      role: "Co-Founder & Creative Director",
      bio: "MS candidate in Design Studies from Harvard GSD with 7 years of experience in AR/VR, volumetric capture, and motion-driven storytelling.",
      image: carlyHeadshot,
      linkedin: "https://www.linkedin.com/in/carly-lave/"
    }
  ];

  return (
    <section id="team" className="team-section">
      <div className="team-container">
        <div className="team-header">
          <h2 className="team-title">Our Team</h2>
          <p className="team-subtitle">Meet the founders behind Immerse3D.</p>
        </div>

        <div className="team-grid">
          {team.map((member, index) => (
            <div key={index} className="team-member">
              <div className="member-image-container">
                <img 
                  src={member.image} 
                  alt={member.name}
                  className="member-image"
                />
              </div>
              <h3 className="member-name">{member.name}</h3>
              <p className="member-role">{member.role}</p>
              <p className="member-bio">{member.bio}</p>
              <div className="member-social">
                <a href={member.linkedin} className="social-link" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="social-icon" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team; 