import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-container">
        <div className="about-content">
          <h2 className="about-title">About Immerse3D</h2>
          <p className="about-description">
            Immerse3D is a creative technology studio fueled by the power of human performance. We craft next-generation stories with emerging technologies that unite people through movement and emotion—blurring the boundary between digital and physical reality. Every story puts the human experience front and center.
          </p>
          <p className="about-description">
            We partner with artists, brands, and creators to produce engaging immersive content where the viewer can interact with life-like human performances in digital worlds. The end results can be deployed across platforms, from AR/VR to 2D video and web applications. Immerse3D pushes the boundary of digital content creation.
          </p>
          <div className="about-features">
            <div className="feature">
              <h3>Our Mission</h3>
              <p>We're redefining immersive content by making it feel real. While digital experiences are evolving, most AR/VR activations feel artificial, detached, or gimmicky. We're changing that.</p>
            </div>
            <div className="feature">
              <h3>Our Vision</h3>
              <p>We envision a future where brands and artists can create unforgettable experiences that blend entertainment and marketing, keeping audiences engaged in ways never before possible.</p>
            </div>
            <div className="feature">
              <h3>Our Impact</h3>
              <p>Through our work at MIT's Arts Incubator and Immersion Lab, we're pushing the boundaries of what's possible in immersive experiences, one project at a time.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About; 