import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Immerse3Dlogo.png';
import backgroundVideo from '../assets/videos/Hero Section Background Video.mp4';
import Partners from '../components/Partners';
import About from '../components/About';
import Services from '../components/Services';
import Team from '../components/Team';
import Contact from '../components/Contact';
import Nav from '../components/Nav';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <Nav />
      <section className="hero-section">
        <video autoPlay loop muted playsInline className="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1 className="hero-title">
            Immersive Storytelling<br />
            Redefined
          </h1>
          <p className="hero-subtitle">
            Crafting next-gen AR, VR, and volumetric experiences
          </p>
          <button className="learn-more-button">LEARN MORE</button>
        </div>
      </section>

      <Partners />
      <About />
      <Services />
      <Team />
      <Contact />
    </div>
  );
};

export default Home; 